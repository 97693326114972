import { useIntersectionObserver } from '@/scripts/helpers/utils';

export default class CountUp {
    constructor($element, options = {}) {
        this.$element = $element;
        this.value = Number($element.getAttribute('data-count-up'));
        this.options = {
            useEasing: true,
            useGrouping: true,
            duration: 5,
            separator: '.',
            decimal: '.',
            ...options,
        };
        this.countUp = null;
        this.unobserve = null;
    }

    async init() {
        const { CountUp } = await import(/* webpackChunkName: "countup" */ 'countup.js');
        const dataOptions = this.$element.dataset;

        Object.keys(dataOptions).forEach(key => {
            if (key !== 'countUp') {
                this.options[key] = dataOptions[key];
            }
        });

        const decimalValue = this.value.toString().split('.');
        const decimalPlaces = decimalValue.length >= 2 ? decimalValue[decimalValue.length - 1] : 0;

        this.options.decimalPlaces = decimalPlaces.length;

        this.unobserve = useIntersectionObserver(this.$element, target => {
            if (target.isIntersecting) {
                this.countUp = new CountUp(this.$element, this.value, this.options);
                this.countUp.start();
                // this.unobserve();
            } else {
                this.unobserve = null;
                this.countUp = null;
            }
        });
    }
}
