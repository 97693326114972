import { hiddenScroll, visibleScroll } from '@helpers/utils';
export default class Navbar {
    constructor($navbar) {
        this.$navbar = $navbar;
        this.$menu = this.$navbar.querySelector('[data-menu]');
        this.$toggler = this.$navbar.querySelector('[data-menu-toggler]');
        this.$subsHolder = this.$navbar.querySelector('[data-subs-mob]');
        this.handler = this.handler.bind(this);
        this.moveSubMenu = this.moveSubMenu.bind(this);
    }

    init() {
        this.$navbar.removeEventListener('click', this.handler);
        this.$navbar.addEventListener('click', this.handler);
        this.moveSubMenu();
    }

    handler(e) {
        const $toggler = e.target.closest('[data-menu-toggler]') || null;
        const $subToggler = e.target.closest('[data-sub-menu-toggler]') || null;

        if ($toggler) {
            $toggler.classList.toggle('is-active');
            this.$menu.classList.toggle('is-active');
            this.$menu.classList.contains('is-active') ? hiddenScroll() : visibleScroll();
            this.$subsHolder
                .querySelectorAll('[data-sub-menu-id].is-active')
                .forEach($el => $el.classList.remove('is-active'));
        } else if ($subToggler) {
            e.preventDefault();
            const { subMenuToggler, id } = $subToggler.dataset;
            const $subMenu = this.$subsHolder.querySelector(`[data-sub-menu-id="${id}"]`);

            switch (subMenuToggler) {
                case 'open':
                    $subMenu.classList.add('is-active');
                    break;
                case 'hidden':
                    $subMenu.classList.remove('is-active');
                    break;

                default:
                    break;
            }
        }
    }

    moveSubMenu() {
        this.$menu.querySelectorAll('.menu-item-has-children > a').forEach(($el, i) => {
            $el.setAttribute('data-sub-menu-toggler', 'open');
            $el.setAttribute('data-id', i);
            const parent = $el.closest('.menu-item-has-children');
            const clone = parent.cloneNode(true);
            const subMenu = document.createElement('ul');
            subMenu.classList.add('sub-menu-child');
            subMenu.setAttribute('data-sub-menu-id', i);
            subMenu.append(clone);
            const $cloneSubToggler = subMenu.querySelector('.menu-item-has-children > a');
            $cloneSubToggler.setAttribute('data-sub-menu-toggler', 'hidden');
            this.$subsHolder.append(subMenu);
        });
    }

    destroy() {
        visibleScroll();
        this.$menu.querySelectorAll('[data-sub-menu-toggler]').forEach($el => {
            $el.removeAttribute('data-sub-menu-toggler');
            $el.removeAttribute('data-id');
        });
        this.$subsHolder.innerHTML = '';
        this.$menu.classList.remove('is-active');
        this.$toggler.classList.remove('is-active');
        this.$navbar.removeEventListener('click', this.handler);
    }
}
