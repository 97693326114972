export default class VideoPlayer {
    constructor($wrap, options = {}) {
        this.$wrap = $wrap;
        this.$player = this.$wrap.querySelector('video');
        this.$btnPlay = this.$wrap.querySelector('[data-control="play"]');
        this.options = options;
        this.handlerControl = this.handlerControl.bind(this);
    }

    init() {
        this.$wrap.addEventListener('click', this.handlerControl);
    }

    handlerControl(e) {
        e.preventDefault();

        if (!this.$player.playing) {
            this.$player.play();
            this.$btnPlay.hidden = true;
        } else if (this.$player.playing) {
            this.$player.pause();
            this.$btnPlay.removeAttribute('hidden');
        }

        // const $target = e.target.closest('[data-control]');
        // if ($target && !this.$player.playing) {
        //     const name = $target.getAttribute('data-control');
        //     switch (name) {
        //         case 'play':
        //             this.$player.play();
        //             $target.hidden = true;
        //             break;

        //         default:
        //             break;
        //     }
        // }
    }

    destroy() {
        this.$player.pause();
        this.$player.currentTime = 0;
        this.$btnPlay.removeAttribute('hidden');
        this.$wrap.removeEventListener('click', this.handlerControl);
    }
}
