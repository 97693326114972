import { navigation, pagination } from '@components/UI/slider/slider.function';

export default class Slider {
    constructor($slider) {
        this.$slider = $slider;
    }

    init() {
        import(/* webpackChunkName: "swiper" */ 'swiper/core').then(module => {
            const { Navigation, Pagination } = module;
            const Swiper = module.default;
            Swiper.use([Navigation, Pagination]);
            const $slider = this.$slider;

            this.swiper = new Swiper($slider, {
                spaceBetween: 0,
                slidesPerView: 'auto',
                initialSlide: 0,
                watchOverflow: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                navigation: { ...navigation($slider) },
                pagination: { ...pagination($slider) },
            });
        });
    }

    destroy() {
        if (this.swiper !== null) {
            this.swiper.destroy();
            this.swiper = null;
        }
    }
}
