import VideoPlayer from '@components/UI/media/videoPlayer/VideoPlayer';
import { hiddenScroll, visibleScroll } from '@helpers/utils';

export default class VideoInPopup {
    constructor($wrap, options = {}) {
        this.$wrap = $wrap;
        this.options = options;
        this.video = null;
        this.$videoWrap = null;
        this.$popup = null;
        this.handlerControl = this.handlerControl.bind(this);
        this.transitionend = this.transitionend.bind(this);
    }

    init() {
        this.$wrap.removeEventListener('click', this.handlerControl);
        this.$wrap.addEventListener('click', this.handlerControl);
    }

    handlerControl(e) {
        const $target = e.target.closest('[data-popup-control-video]') || null;

        if ($target) {
            e.preventDefault();
            this.video?.destroy();
            this.video = null;
            const { popup } = $target.dataset;
            const $wrap = $target.closest('[data-video-wrap]');
            const $innerWrap = $wrap.innerHTML;
            this.$popup = document.getElementById(`${popup}`);
            this.$videoWrap = this.$popup.querySelector('[data-popup-video]');
            this.$videoWrap.innerHTML = $innerWrap;
            const $bthControl = this.$videoWrap.querySelector('[data-popup-control-video]');
            $bthControl.removeAttribute('data-popup-control-video');
            $bthControl.removeAttribute('data-popup-control-mq');
            $bthControl.removeAttribute('data-popup');

            this.$popup.addEventListener('transitionend', this.transitionend);
            this.$popup.classList.add('js-popup-opened');

            this.video = new VideoPlayer(this.$videoWrap);
            this.video.init();
            hiddenScroll();
        }
    }

    transitionend(e) {
        if (e.target.classList.contains('popup') && !e.target.classList.contains('js-popup-opened')) {
            if (this.video !== null) {
                this.video?.destroy();
                this.video = null;
                this.$popup = null;
                this.$videoWrap.innerHTML = '';
            }
            e.target.removeEventListener('transitionend', this.transitionend);
        }
    }

    destroy() {
        if (this.video !== null) {
            this.video?.destroy();
            this.video = null;
        }
        if (this.$popup) {
            this.$popup.removeEventListener('transitionend', this.transitionend);
            this.$popup.classList.remove('js-popup-opened');
            visibleScroll();
            this.$popup.addEventListener('transitionend', this.transitionend);
        }
        this.$wrap.removeEventListener('click', this.handlerControl);
    }
}
