const loadLink = (href, cb, to = document.body, append = 'appendChild', rel = 'stylesheet') => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = rel;
    link.onload = cb;
    to[append](link);
};

const placeholders = {
    firstname: 'Jane Appleberry',
    email: 'Jane@appleberry.com',
    mobilephone: '+1 000 - 000 - 00',
};

export const setStyleForForm = (head, body) => {
    loadLink('https://fonts.googleapis.com', () => {}, head, 'appendChild', 'preconnect');

    loadLink('https://fonts.gstatic.com', () => {}, head, 'appendChild', 'preconnect');

    loadLink(
        'https://fonts.googleapis.com/css2?family=Poppins&display=swap',
        () => {},
        head,
        'appendChild',
        'stylesheet'
    );

    const style = document.createElement('style');

    style.innerHTML = `
        body {
            font-family: Poppins, sans-serif !important;
        }

        form {
            margin-top: 30px;
        }

        label:not(.hs-error-msg) {
            font-family: Poppins, sans-serif !important;
            font-size: 16px !important;
            display: block !important;
            font-weight: 400 !important;
            line-height: 150% !important;
            color: #202024 !important;
            margin-bottom: 5px !important;
        }

        label.hs-error-msg,
        label.hs-main-font-element {
            font-family: Poppins, sans-serif !important;
            font-size: 14px !important;;
            line-height: 120% !important;;
            color: #dc3545 !important;
            margin-bottom: 0 !important;
        }

        input {
            font-family: Poppins, sans-serif !important;
            width: 100% !important;
            height: auto !important;
            background-color: #fff !important;
            border: 1px solid #b4d6db !important;
            color: #202024 !important;
            border-radius: 12px !important;
            line-height: 155% !important;
            padding: 12px 16px !important;
            will-change: border-color !important;
            transition: color .15s ease-in-out,border-color .15s ease-in-out;
        }

        input.error,
        input.invalid {
            border-color: #dc3545 !important;
        }

        input:not(.error):hover {
            border-color: #85878f !important;
        }

        input::placeholder {
            color: $muted-100;
            line-height: 155%;
        }

        input:-moz-placeholder {
            color: $muted-100;
            line-height: 155%;
        }

        input::-moz-placeholder {
            color: $muted-100;
            line-height: 155%;
        }

        input:-ms-input-placeholder {
            color: $muted-100;
            line-height: 155%;
        }

        input::-webkit-input-placeholder {
            color: $muted-100;
            line-height: 155%;
        }

        input:focus::placeholder {
            opacity: 0;
            transition: opacity .3s ease;
        }

        input:focus:-moz-placeholder {
            opacity: 0;
            transition: opacity .3s ease;
        }

        input:focus::-moz-placeholder {
            opacity: 0;
            transition: opacity .3s ease;
        }

        input:focus:-ms-input-placeholder {
            opacity: 0;
            transition: opacity .3s ease;
        }

        input:focus::-webkit-input-placeholder {
            opacity: 0;
            transition: opacity .3s ease;
        }

        [type="submit"] {
            font-family: Poppins, sans-serif !important;
            position: relative;
            white-space: nowrap;
            font-size: 17px;
            padding: 14px 25.5px;
            outline: none;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 130%;
            border-radius: 20px !important;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: 1px solid transparent;
            color: #fff !important;
            background-color: #3f3fe8 !important;
            opacity: 1;
            font-weight: 400;
            box-shadow: 0 20px 27px -13px rgb(0 55 252 / 44%);
            transition: color .15s ease-in-out,
            background-color .15s ease-in-out,
            border-color .15s ease-in-out,
            box-shadow .15s ease-in-out,
            opacity .15s ease-in-out;
        }

        [type="submit"]:not([disabled]):hover {
            background-color: #4d74ff !important;
            box-shadow: 0 20px 27px -13px rgb(0 101 252 / 44%) !important;
        }

        [type="submit"]:not([disabled]):active {
            background-color: #3d3dc7 !important;
            box-shadow: 0 20px 27px -13px rgb(0 55 252 / 44%) !important;
        }

        .field {
            margin-bottom: 16px !important;
        }

        .hs-form-required {
            display: none !important;
        }

        .submitted-message {
            font-family: Poppins, sans-serif !important;
            white-space: nowrap !important;
            font-size: 18px !important;
            margin-top: 5px !important;
        }

        .hubspot-link__container {
            display: none !important;
        }`;
    body.appendChild(style);

    body.querySelectorAll('[name]').forEach(input => {
        if (placeholders[input.name]) {
            input.placeholder = placeholders[input.name];
        }
    });

    body.querySelectorAll('[type="submit"]').forEach(input => {
        input.value = 'Request a Demo';
    });
};
