import { navigation, pagination } from '@components/UI/slider/slider.function';
export default class TwoSliders {
    constructor({ sliderMain, sliderThumbs }) {
        this.$sliderMain = sliderMain;
        this.$sliderThumbs = sliderThumbs;
        this.sliderMain = null;
        this.sliderThumbs = null;
    }

    init() {
        const self = this;

        import(/* webpackChunkName: "swiper" */ 'swiper/core').then(module => {
            const { Navigation, Thumbs, Pagination } = module;
            const Swiper = module.default;
            Swiper.use([Navigation, Thumbs, Pagination]);

            const $sliderMain = this.$sliderMain;
            const $sliderThumbs = this.$sliderThumbs;

            self.sliderThumbs = new Swiper($sliderThumbs, {
                spaceBetween: 15,
                slidesPerView: 1,
                initialSlide: 0,
                freeMode: false,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                watchOverflow: true,
                observeParents: true,
                observer: true,
                observeSlideChildren: true,
                navigation: { ...navigation($sliderThumbs) },
                pagination: { ...pagination($sliderThumbs) },
                // breakpoints: {
                //     991: {

                //     },
                // },
                // on: {
                //     init() {
                //     },
                //     slideChange() {
                //     },
                //     resize() {
                //     },
                // },
            });

            self.sliderMain = new Swiper($sliderMain, {
                direction: 'horizontal',
                spaceBetween: 15,
                slidesPerView: 1,
                navigation: { ...navigation($sliderMain) },
                pagination: { ...pagination($sliderMain) },
                on: {
                    // init() {
                    //     setTimeout(updateFraction, 0, this);
                    // },
                    // slideChange() {
                    //     updateFraction(this);
                    // },
                    // resize() {
                    //     updateFraction(this);
                    // },
                    // transitionEnd: function () {
                    //     galleryThumbs.slideTo(this.snapIndex);
                    // },
                },
                thumbs: {
                    swiper: self.sliderThumbs,
                },
            });
        });
    }

    destroy() {
        if (this.swiper !== null) {
            this.swiper.destroy();
            this.swiper = null;
        }
    }
}
