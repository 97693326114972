// import { hiddenScroll, visibleScroll } from '@helpers/utils';

import { createError } from '@components/UI/media/uploader/uploader.function';
import { fireSome } from '@helpers/utils';

export default class UploadFile {
    constructor($wrap, options = {}) {
        this.$wrap = $wrap;
        this.$input = $wrap.querySelector('input[type="file"]');
        this.$result = $wrap.querySelector('[data-result]');
        this.$clear = $wrap.querySelector('[data-clear]');
        this.options = options;
        this.handlerChange = this.handlerChange.bind(this);
        this.handlerClear = this.handlerClear.bind(this);
    }

    init() {
        this.$input.addEventListener('change', this.handlerChange);
        this.$wrap.addEventListener('click', this.handlerClear);
    }

    async handlerChange(e) {
        const input = e.target;
        const $error = this.$wrap.querySelector('.error');
        const isFile = input.files && input.files[0];
        const isCorrectSize = input.files[0].size <= this.options.uploader.maxSize;
        const { lookup } = await import(/* webpackChunkName: "mime-types" */ 'mime-types');
        const type = lookup(input.files[0].name);
        const isCorrectExtension = fireSome(this.options.uploader.extensions, type);

        if (!isFile || !isCorrectSize || !isCorrectExtension) {
            createError(this.$wrap, input, this.$result, this.$clear, $error, {
                isDocument: this.options.uploader.document,
            });
            return;
        }
        $error?.remove();
        this.$result.innerText = input.files[0].name;
        this.$clear.removeAttribute('hidden');
    }

    handlerClear(e) {
        const $target = e.target.closest('[data-clear]') || null;
        if ($target) {
            const $error = this.$wrap.querySelector('.error');
            $error?.remove();
            this.$input.value = '';
            this.$result.innerText = '';
            $target.setAttribute('hidden', true);
        }
    }

    destroy() {
        this.$input.removeEventListener('click', this.handlerChange);
        this.$wrap.removeEventListener('click', this.handlerClear);
    }
}
