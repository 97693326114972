import anime from 'animejs/lib/anime.es.js';
import { wrapWords, wrapChars } from '@helpers/utils';

const removeFromElementsPropWillChange = elements => {
    elements.forEach(({ target }) => {
        target.classList.add('reset-will-change');
    });
};

// export const headerAnime = $header => {
//     anime({
//         targets: $header.querySelector('.js-header-content'),
//         translateY: {
//             value: ['-150%', '0%'],
//             delay: 1000,
//             duration: 500,
//             easing: 'easeInOutSine',
//         },
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//             const $withUsIllustration = document.getElementById('with-us-illustration');
//             $withUsIllustration ? $withUsIllustration.getLottie().play() : null;
//             buttonAnime(document.getElementById('with-us-btn-mob'));
//         },
//     });
// };

export const fadeInRight = ($element, duration = 1200, delay = 50) => {
    anime({
        targets: $element,
        translateX: {
            value: ['150%', '0%'],
            delay: 50,
            duration: duration,
            easing: 'easeInOutSine',
        },
        opacity: {
            value: 1,
            delay: delay + 50,
            duration: duration * 0.7,
            easing: 'easeInOutSine',
        },
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

export const fadeInLeft = ($element, duration = 1200, delay = 50) => {
    anime({
        targets: $element,
        translateX: {
            value: ['-150%', '0%'],
            delay: 50,
            duration: duration,
            easing: 'easeInOutSine',
        },
        opacity: {
            value: 1,
            delay: delay + 50,
            duration: duration * 0.7,
            easing: 'easeInOutSine',
        },
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

export const titleAnime = ($title, translateY) => {
    $title.innerHTML = wrapWords($title.textContent);
    const $words = $title.querySelectorAll('.word');
    $words.forEach($word => {
        $word.innerHTML = wrapChars($word.textContent);
    });
    const $letters = $title.querySelectorAll('.letter');

    anime({
        targets: $title,
        opacity: 1,
        duration: 0,
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });

    anime({
        targets: $letters,
        translateY: {
            value: [translateY, 0],
            easing: 'easeOutElastic(1, .4)',
        },
        scale: [0, 1],
        duration: 1500,
        delay: (_, i) => 20 * i,
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

export const liftUpAnime = ($block, translateY, duration, delay, descriptor = false) => {
    anime({
        targets: $block,
        translateY: {
            value: [translateY, 0],
            delay: delay,
            duration: duration,
            easing: 'easeInOutSine',
        },
        opacity: {
            value: 1,
            delay: duration - 100,
            duration: delay - 150,
            easing: 'easeInOutSine',
        },
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
            descriptor
                ? buttonAnime($block.closest('[data-holder-anime]').querySelector(`[data-wait-anime="${descriptor}"]`))
                : null;
        },
    });
};

export const buttonAnime = $button => {
    anime({
        targets: $button,
        opacity: {
            value: 1,
            delay: 30,
            duration: 250,
            easing: 'easeInOutSine',
        },
        translateY: {
            value: [20, 0],
            delay: 75,
            duration: 300,
            easing: 'easeInOutSine',
        },
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

export const fadeInLeftRectangle = $rectangle => {
    anime({
        targets: $rectangle,
        opacity: [0.3, 1],
        translateX: {
            value: ['-100vw', '0vw'],
            duration: 1500,
        },
        translateY: {
            value: [-100, 0],
            duration: 1500,
        },
        easing: 'easeInOutSine',
        duration: 2000,
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

export const fadeInRightRectangle = $rectangle => {
    anime({
        targets: $rectangle,
        opacity: [0.3, 1],
        translateX: {
            value: ['100vw', '0vw'],
            duration: 1500,
        },
        translateY: {
            value: [100, 0],
            duration: 1500,
        },
        easing: 'easeInOutSine',
        duration: 2000,
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

export const fadeInRectangleContent = $element => {
    anime({
        targets: $element,
        opacity: 1,
        translateX: {
            value: [250, 0],
            duration: 800,
        },
        rotate: {
            value: [90, 0],
            duration: 1200,
            easing: 'easeInOutSine',
        },
        scale: {
            value: [1.5, 1],
            duration: 1100,
            delay: 800,
            easing: 'easeInOutQuart',
        },
        duration: 800,
        delay: 0,
        easing: 'easeInOutSine',
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

export const fadeIn = ($element, delay = 30, duration = 1000) => {
    anime({
        targets: $element,
        opacity: [0, 1],
        duration: duration,
        delay,
        easing: 'easeInOutSine',
        complete({ animatables }) {
            removeFromElementsPropWillChange(animatables);
        },
    });
};

// export const scaleDecrease = ($element, delay = 0) => {
//     anime({
//         targets: $element,
//         opacity: 1,
//         scale: [2.5, 1],
//         duration: 1100,
//         delay,
//         easing: 'easeInOutSine',
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });
// };

// export const hiHereAnime = $element => {
//     $element.classList.add('animated');
//     const complete = e => {
//         e.target.classList.remove('animated');
//         e.target.removeEventListener('animationend', complete);
//     };
//     $element.addEventListener('animationend', complete);
// };

// export const cardsStatAnime = $statistics => {
//     const $cards = $statistics.querySelectorAll('.card');
//     const $cardsHolders = $statistics.querySelectorAll('.card__holder');

//     anime({
//         targets: $cards,
//         translateY: {
//             value: ['100%', 0],
//         },
//         opacity: {
//             value: 1,
//         },
//         delay: (_, i) => 400 + i * 150,
//         duration: (_, i) => 400 + i * 200,
//         easing: 'easeInOutSine',
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });

//     anime({
//         targets: $cardsHolders,
//         translateY: {
//             value: ['70%', 0],
//         },
//         opacity: {
//             value: 1,
//         },
//         delay: (_, i) => 1100 + i * 150,
//         duration: (_, i) => 1200 - i * 100,
//         easing: 'easeInOutSine',
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });
// };

// export const cardsAdvantagesAnime = $advantages => {
//     const $cards = $advantages.querySelectorAll('.advantages__card');
//     const $cardsHolders = $advantages.querySelectorAll('.card__holder');
//     const $cardsTexts = $advantages.querySelectorAll('.text-anime');

//     anime({
//         targets: $cards,
//         rotate: {
//             value: ['-45deg', 0],
//         },
//         scale: [0, 1],
//         opacity: {
//             value: 1,
//         },
//         delay: (_, i) => 500 + i * 150,
//         duration: (_, i) => 500 + i * 100,
//         easing: 'easeInOutSine',
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });

//     anime({
//         targets: $cardsHolders,
//         opacity: {
//             value: 1,
//         },
//         delay: (_, i) => 600 + i * 100,
//         duration: 900,
//         easing: 'easeInOutSine',
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });

//     anime({
//         targets: $cardsTexts,
//         translateY: {
//             value: ['60%', 0],
//         },
//         opacity: {
//             value: 1,
//         },
//         delay: (_, i) => 700 + i * 150,
//         duration: 900,
//         easing: 'easeInOutSine',
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });
// };

// export const cardsAdvantagesAnimated = $steps => {
//     $steps.classList.add('animated');
//     const $items = $steps.querySelectorAll('.card');
//     const complete = e => {
//         e.target.style.transform = 'none';
//         e.target.removeEventListener('animationend', complete);
//     };
//     $items.forEach($item => $item.addEventListener('animationend', complete));
// };

// export const fadeInAnime = ($element, duration, delay) => {
//     anime({
//         targets: $element,
//         opacity: {
//             value: 1,
//         },
//         delay: delay,
//         duration: duration,
//         easing: 'easeInOutSine',
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });
// };

// export const inAnime = $elert => {
//     $elert.innerHTML = wrapWords($elert.textContent);

//     anime({
//         targets: $elert.querySelectorAll('.word'),
//         translateY: {
//             value: [20, 0],
//             easing: 'easeOutElastic(1, .4)',
//         },
//         duration: 1500,
//         delay: (_, i) => 20 * i,
//         complete({ animatables }) {
//             removeFromElementsPropWillChange(animatables);
//         },
//     });
// };
