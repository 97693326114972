export default class SelectInput {
    constructor($selects) {
        this.$selects = $selects;
    }

    init() {
        import(/* webpackChunkName: "@dotburo/select-input" */ '@dotburo/select-input').then(module => {
            const SelectInput = module.default;
            const $selects = this.$selects;
            for (let i = 0; i < $selects.length; i++) {
                const { placeholder } = $selects[i].dataset;
                const resultInput = $selects[i].closest('[data-select-wrap]').querySelector('[data-select-result]');

                const selectInput = new SelectInput($selects[i], {
                    items: [
                        'New York NY, USA',
                        'Newark NJ, USA',
                        'New Orlean LA, USA',
                        'Newport Beach CA, USA',
                        'Newport Beach CA, USA',
                    ],
                    placeHolder: placeholder,
                    allowRemove: false,
                });

                selectInput.on('selected', e => {
                    const { value } = e.detail;
                    resultInput.value = value;
                });
            }
        });
    }
}
