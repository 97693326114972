import { EventEmitter } from '@helpers/EventEmitter';
import Popups from '@components/UI/Popups';
import { resizer } from '@helpers/resizer';
import { setCurrentYear } from '@helpers/utils';
import { PDF, DOC, DOCX } from '@helpers/constants';
import AnimeDetectViewport from '@components/animeDetectViewport/AnimeDetectViewport';
import TwoSliders from '@components/UI/slider/TwoSliders';
import Slider from '@components/UI/slider/Slider';
import CountUp from '@components/UI/countUp/CountUp';
import Navbar from '@components/UI/navbar/Navbar';
import SelectInput from '@components/UI/selectInput/SelectInput';
import VideoInPopup from '@components/UI/media/videoPlayer/VideoInPopup';
import Tabs from '@components/UI/tabs/Tabs';
import Accordion from '@components/UI/accordion/Accordion';
import ResendCode from '@components/UI/resendCode/ResendCode';
import datepicker from 'js-datepicker';
import UploadFile from '@components/UI/media/uploader/uploadFile';
import VideoPlayer from '@components/UI/media/videoPlayer/VideoPlayer';
import Validation from '@helpers/validation/Validation';
import { changePricingPrice } from '@components/UI/changePricingPrice';
import { setStyleForForm } from './components/hsForm/hsform';

const emitter = new EventEmitter();

//NOTE: Popups
{
    const $popups = document.querySelectorAll('.popup');
    if ($popups.length) {
        const popups = new Popups($popups, {});
        popups.init();
    }
}

// NOTE: Two Sliders
{
    const $slidersWrap = document.querySelectorAll('[data-two-sliders]');

    if ($slidersWrap.length) {
        $slidersWrap.forEach($wrap => {
            const sliders = {};
            $wrap.querySelectorAll('[data-slider-el]').forEach($slider => {
                if ($slider.getAttribute('data-slider-el') === 'main') {
                    sliders.sliderMain = $slider;
                } else if ($slider.getAttribute('data-slider-el') === 'thumbs') {
                    sliders.sliderThumbs = $slider;
                }
            });

            Object.keys(sliders).length === 2 && new TwoSliders({ ...sliders }).init();
        });
    }
}

// NOTE: Slider
{
    const $sliders = document.querySelectorAll('[data-slider]');

    if ($sliders.length) {
        $sliders.forEach($slider => {
            new Slider($slider).init();
        });
    }
}

// NOTE: Slider media query
{
    const $sliders = document.querySelectorAll('[data-slider-mq]');

    if ($sliders.length) {
        $sliders.forEach($slider => {
            let swiper = null;
            const mq = window.matchMedia(`(max-width: ${$slider.dataset.sliderMq}px)`);

            const checkMq = () => {
                if (mq.matches) {
                    $slider.removeAttribute('class');
                    $slider.classList.add('swiper-container');
                    swiper = new Slider($slider);
                    swiper.init();
                } else if (swiper !== null) {
                    $slider.removeAttribute('class');
                    $slider.classList.remove('swiper-container');
                    swiper.destroy();
                    swiper = null;
                }
            };

            checkMq();
            mq.addListener(checkMq);
        });
    }
}

// NOTE: Count up
{
    const $countUpElements = document.querySelectorAll('[data-count-up]');

    if ($countUpElements.length) {
        $countUpElements.forEach($el => {
            new CountUp($el).init();
        });
    }
}

// NOTE: Tabs
{
    const $tabs = document.querySelectorAll('[data-tabs]');

    if ($tabs.length) {
        for (let i = 0; i < $tabs.length; i++) {
            new Tabs($tabs[i]).init();
        }
    }
}

// NOTE: Video
{
    const $wrapsPlayers = document.querySelectorAll('[data-video]');
    const $wrapsPlayersMq = document.querySelectorAll('[data-mq-video]');
    const $wrapsPlayerInPopup = document.querySelectorAll('[data-video-wrap]');

    if ($wrapsPlayers.length) {
        for (let i = 0; i < $wrapsPlayers.length; i++) {
            new VideoPlayer($wrapsPlayers[i]).init();
        }
    }

    // NOTE: Video media query
    if ($wrapsPlayersMq.length) {
        for (let i = 0; i < $wrapsPlayersMq.length; i++) {
            let video = null;
            const mq = window.matchMedia(`(max-width: ${$wrapsPlayersMq[i].dataset.mqVideo}px)`);

            const checkMq = () => {
                if (mq.matches) {
                    video = null;
                    video = new VideoPlayer($wrapsPlayersMq[i]);
                    video.init();
                } else if (video !== null) {
                    video.destroy();
                    video = null;
                }
            };

            checkMq();
            mq.addListener(checkMq);
        }
    }

    // NOTE: Video in popup
    if ($wrapsPlayerInPopup.length) {
        for (let i = 0; i < $wrapsPlayerInPopup.length; i++) {
            let videoInPopup = null;
            const $btnControl = $wrapsPlayerInPopup[i].querySelector('[data-popup-control-video]');

            const [mqValue, value] = $btnControl.dataset?.popupControlMq?.split(':');

            if (mqValue) {
                const mq =
                    mqValue === 'min'
                        ? window.matchMedia(`(min-width: ${value}px)`)
                        : window.matchMedia(`(max-width: ${value}px)`);

                const checkMq = () => {
                    if (mq.matches) {
                        videoInPopup?.destroy();
                        videoInPopup = new VideoInPopup($wrapsPlayerInPopup[i]);
                        videoInPopup.init();
                    } else if (videoInPopup !== null) {
                        videoInPopup.destroy();
                        videoInPopup = null;
                    }
                };

                checkMq();
                mq.addListener(checkMq);
            }
        }
    }

    if ($wrapsPlayers.length || $wrapsPlayersMq.length || $wrapsPlayerInPopup.length) {
        Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
            get: function () {
                return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
            },
        });
    }
}

//NOTE: Accordion
{
    const $accordions = document.querySelectorAll('[data-accordion]');

    if ($accordions.length) {
        for (let i = 0; i < $accordions.length; i++) {
            new Accordion($accordions[i]).init();
        }
    }
}

// NOTE: Accordion media query

{
    const $accordions = document.querySelectorAll('[data-accordion-mq]');

    if ($accordions.length) {
        for (let i = 0; i < $accordions.length; i++) {
            let accordion = null;
            const mq = window.matchMedia(`(max-width: ${$accordions[i].dataset.accordionMq}px)`);

            const checkMq = () => {
                if (mq.matches) {
                    accordion?.destroy();
                    accordion = new Accordion($accordions[i]);
                    accordion.init();
                } else if (accordion !== null) {
                    accordion?.destroy();
                    accordion = null;
                }
            };

            checkMq();
            mq.addListener(checkMq);
        }
    }
}

// NOTE: Navbar
{
    const $navbar = document.querySelector('[data-nav]') || null;

    if ($navbar) {
        let navbar = null;
        const mq = window.matchMedia('(max-width: 991px)');

        const checkMq = () => {
            if (mq.matches) {
                navbar?.destroy();
                navbar = new Navbar($navbar);
                navbar.init();
            } else if (navbar !== null) {
                navbar?.destroy();
                navbar = null;
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

// NOTE: Resend code
{
    const $ResendCode = document.querySelector('[data-resend-code]') || null;

    if ($ResendCode) {
        new ResendCode($ResendCode).init();
    }
}

// NOTE: Datapicker
{
    const $datepickers = document.querySelectorAll('[data-datepicker]');

    if ($datepickers.length) {
        const toggleClass = $element => {
            const $wrap = $element.closest('[data-datepicker-wrap]');
            $wrap?.classList.toggle('is-open');
        };
        for (let i = 0; i < $datepickers.length; i++) {
            datepicker($datepickers[i], {
                customDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                minDate: new Date(Date.now()),
                formatter: (input, date, instance) => {
                    const value = date.toLocaleDateString('en-US');
                    input.value = value;
                },
                onShow: instance => {
                    const { calendarContainer } = instance;
                    toggleClass(calendarContainer);
                },
                onHide: instance => {
                    const { calendarContainer } = instance;
                    toggleClass(calendarContainer);
                },
            });
        }
    }
}

// NOTE: Select Input
{
    const $selects = document.querySelectorAll('[data-select-input]');

    if ($selects.length) {
        new SelectInput($selects).init();
    }
}

// NOTE: Main animation
{
    const $elements = document.querySelectorAll('[data-el-anime]');

    if ($elements.length) {
        const detectViewport = new AnimeDetectViewport($elements);
        detectViewport.init();
    }
}

//NOTE: Set current Year
{
    const $holderYear = document.querySelector('[data-current-year]') || null;

    if ($holderYear) {
        setCurrentYear($holderYear);
    }
}

//NOTE: Upload file
{
    const $wraps = document.querySelectorAll('[data-upload="file"]');

    if ($wraps.length) {
        for (let i = 0; i < $wraps.length; i++) {
            new UploadFile($wraps[i], {
                uploader: {
                    maxSize: 25e6,
                    extensions: [PDF, DOC, DOCX],
                    document: true,
                },
            }).init();
        }
    }
}

//NOTE: Form validation
{
    const $forms = document.querySelectorAll('[data-validation]');

    if ($forms.length) {
        for (let i = 0; i < $forms.length; i++) {
            new Validation($forms[i], {}).init();
        }
    }
}

//NOTE: Pricing change price
{
    const $pricingChange = document.querySelector('[data-pricing-change]') || null;

    if ($pricingChange) {
        changePricingPrice($pricingChange);
    }
}

//NOTE: script for hbspt-form
{
    window.addEventListener('load', () => {
        const $formIframe = document.querySelectorAll('[id^="hs-form-iframe"]') || [];

        if ($formIframe.length) {
            const style = document.createElement('style');
            style.innerHTML = `.hbspt-form > iframe {
            height: 490px !important;
            overflow: visible !important;
        }`;
            document.head.appendChild(style);
            for (let i = 0; i < $formIframe.length; i++) {
                const head = $formIframe[i].contentDocument.head;
                const body = $formIframe[i].contentDocument.body;
                setStyleForForm(head, body);
            }
        }
    });
}

// sleep(5000).then(() => {
//     console.log('end');
// });

// Resize function
resizer({ emitter, ms: 300 });
