export const changePricingPrice = $parent => {
    $parent.addEventListener('change', ({ target }) => {
        const isTarget = ['INPUT'].includes(target.nodeName);

        if (isTarget && target.type === 'checkbox') {
            $parent.querySelectorAll(`[data-change-price="${target.name}"]`).forEach($changePrice => {
                $changePrice.classList.remove('await-action');
                $changePrice.querySelectorAll('[data-price]').forEach($el => {
                    $el.classList.remove('is-visible');

                    if (target.checked && $el.dataset.price === 'new') {
                        $el.classList.add('is-visible');
                    } else if (!target.checked && $el.dataset.price === 'old') {
                        $el.classList.add('is-visible');
                    }
                });
            });
        }
    });
};
