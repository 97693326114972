export const createError = ($wrap, input, $result, $clear, $error, { isDocument = false }) => {
    const format = isDocument ? 'pdf, doc, docx,' : 'png, jpg, jpeg,';
    input.value = '';
    $result.innerText = '';
    $clear.setAttribute('hidden', true);
    $error?.remove();
    const newError = document.createElement('span');
    newError.classList.add('error');
    newError.innerText = `File format only : ${format} max size 25Mb`;
    $wrap.append(newError);
};
