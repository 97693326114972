import { initNumbersFields } from '@components/UI/resendCode/resendCode.function';
// import { initTimer } from '@components/UI/timer';

export default class ResendCode {
    constructor($parent) {
        this.$parent = $parent;
        this.$holder = this.$parent.querySelector('[data-resend-code-elements]');
        this.$elements =
            [...this.$holder.querySelectorAll('input')].map(($el, i) => {
                return {
                    name: $el.name,
                    index: i,
                    target: $el,
                };
            }) || [];
        // this.$menu = this.$navbar.querySelector('[data-menu]');
        // this.$toggler = this.$navbar.querySelector('[data-menu-toggler]');
        // this.$subsHolder = this.$navbar.querySelector('[data-subs-mob]');
        // this.handler = this.handler.bind(this);
        this.controlNumbersFields = this.controlNumbersFields.bind(this);
    }

    init() {
        console.log(this.$parent);
        initNumbersFields(this.$elements);
        this.controlNumbersFields();
    }

    controlNumbersFields() {
        const length = this.$elements.length;
        this.$elements[0]?.target.focus();

        this.$holder.addEventListener('input', e => {
            const isTarget = ['INPUT'].includes(e.target.nodeName);

            if (isTarget) {
                const $target = e.target;
                const { index } = this.$elements.find($el => $el.target === $target) || null;
                // console.log($currentInput);

                if (length - 1 !== index) {
                    const $nextInput = this.$elements[index + 1];
                    if ($target.value && $nextInput?.target.hasAttribute('disabled')) {
                        $nextInput?.target.removeAttribute('disabled');
                        $nextInput?.target.focus();
                    }
                }
            }
        });
    }
}
